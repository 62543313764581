import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./app/App";
import { Toaster } from "react-hot-toast";
import { Elements } from "@stripe/react-stripe-js";
import { UserContextProvider } from "./context/UserContext";
import PaymentMethodProvider from "./context/PaymentMethodContext";
import AuthContextProvider from "./context/AuthContext";
import { loadStripe } from "@stripe/stripe-js";

export const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <Elements stripe={stripePromise}>
      <UserContextProvider>
        <PaymentMethodProvider>
          <AuthContextProvider>
            <App />
          </AuthContextProvider>
        </PaymentMethodProvider>
      </UserContextProvider>
    </Elements>
  </>
);
