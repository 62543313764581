import { getMessaging, getToken, onMessage } from "firebase/messaging";
import firebaseApp from "../utils/init-firebase";

const messaging = getMessaging(firebaseApp);

async function getMessagingToken() {
    var token = null;
    await getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_NOTIF_VAPID_KEY,
    })
        .then((messagingToken) => {
            token = messagingToken;
        })
        .catch((error) => {
            console.log(error);
        });

    return token;
}

const MessagingService = {
    getMessagingToken,
	onMessage,
    messaging
};

export default MessagingService;
