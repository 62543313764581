import axios from "axios";

const { CancelToken } = axios;
const _cancelToken = {};

const AxiosRequest = axios.create({
	baseURL: `${process.env.REACT_APP_API_END_POINT}`,
});

AxiosRequest.interceptors.request.use(
	(config) => {
		try {
			const { cancelToken } = config;
			if (cancelToken) {
				if (_cancelToken[cancelToken]) {
					const source = _cancelToken[cancelToken];
					delete _cancelToken[cancelToken];
					source.cancel();
				}

				const source = CancelToken.source();
				config.cancelToken = source.token;
				_cancelToken[cancelToken] = source;
			}

			// change some global axios configurations
			// add accessToken header before sending api
			// const accessToken = sessionStorage.getItem("authToken");
            const tokenType = config.url.split("/")?.[1] === "admin" ? sessionStorage.getItem("adminAccessToken") : sessionStorage.getItem("authToken");
			config.headers.Authorization = `Bearer ${tokenType}`;
			return config;
		} catch (err) {}
	},
	(error) => {
		Promise.reject(error);
	}
);

AxiosRequest.interceptors.response.use(
	(response) => {
		const { cancelToken } = response.config;
		if (cancelToken) {
			// delete request from queue
			delete _cancelToken[response.config.cancelToken];
		}
		return response;
	},
	(error) => {
		const status = error?.response ? error?.response?.status : null;
		if (status === 401) {
			sessionStorage.clear();
		}
		if (axios.isAxiosError(error) && error?.response?.status === 401) {
			sessionStorage.clear();
		}
		return Promise.reject(error);
	}
);

export default AxiosRequest;
