import {
  Badge,
  Col,
  Container,
  Dropdown,
  Image,
  Modal,
  Nav,
  Navbar,
  NavDropdown,
  Row,
  Spinner,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  BellIcon,
  BellIconBig,
  CheckIcon,
  CloseIcon,
  MembersIcon,
  MessageIcon,
  PlusIcon,
} from "../../shared/Icons";
import User from "../../context/UserContext";
import React, { useContext, useEffect, useMemo, useState } from "react";
import moment from "moment";
import AxiosRequest from "../../utils/AxiosRequest";
import InfiniteScroll from "react-infinite-scroll-component";
import defaultAvtar from "../../assets/images/default-avtar.png";
import toast from "react-hot-toast";
import LogoImage from "../../assets/images/Logo.png";

export const Header = () => {
  const {
    currentUser,
    notifications,
    hasMore,
    containerRef,
    loadmore,
    handleScroll,
    notificationCount,
    setNotifications,
    setPage,
    setNotificationCount,
    setHasMore,
    getNotifications,
    page,
    setCurrentUser,
    isLoading,
    workspacePopup,
    setWorkspacePopup,
    allWorkspaces,
    setAllWorkspaces,
    getAllWorkspaces,
  } = useContext(User);
  const navigate = useNavigate();
  const location = useLocation();
  const isRouteActive = (prefix) => {
    return location.pathname.startsWith(prefix);
  };
  const handleLogout = () => {
    sessionStorage.removeItem("currentUser");
    sessionStorage.removeItem("authToken");
    toast.success("Logout successfully");
    setCurrentUser();
    navigate("/");
    window.location.reload();
  };

  const handleMarkAllAsRead = async () => {
    try {
      const id = [];
      for (let i = 0; i < notifications.length; i++) {
        if (notifications[i].isRead === false) {
          id.push(notifications[i]._id);
        }
      }
      await AxiosRequest.post("/notification/updateNotification", { id: id });
      const updatedNotifications = notifications.filter(
        (notification) => notification.isRead
      );
      setNotifications(updatedNotifications);
      getNotifications();
      setNotificationCount(0);
      setPage(1);
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
      console.log(err);
    }
  };

  const handleUpdateNotification = async (notificationId) => {
    try {
      const id = [notificationId];
      await AxiosRequest.post("/notification/updateNotification", { id: id });
      const updatedNotifications = notifications.map((notification) => {
        if (notification._id === notificationId && !notification.isRead) {
          notification.isRead = true;
          setNotificationCount((prevCount) => prevCount - 1);
        }
        return notification;
      });
      setNotifications(updatedNotifications);
      getNotifications();
      setPage(1);
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
      console.log(err);
    }
  };

  useEffect(() => {
    if (currentUser) getAllWorkspaces({ query: null });
  }, [currentUser]);

  useEffect(() => {
    window.addEventListener("scroll", function () {
      const navbarBgElement = document.querySelector(".navbar-bg");
      if (navbarBgElement) {
        if (window.scrollY > 20) {
          navbarBgElement.classList.add("scrolled");
        } else {
          navbarBgElement.classList.remove("scrolled");
        }
      }
    });
  }, []);

  return (
    <>
      <Navbar expand="lg" className=" navbar-bg fixed-top" id="navbar">
        <Container>
          <Navbar.Brand
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
          >
            <img src={LogoImage} alt="" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <div className="navlink-parent">
                {currentUser !== null && currentUser !== undefined && (
                  <Link
                    className={`navlink${
                      isRouteActive("/my-generation") ? "-active" : ""
                    }`}
                    to="/my-generation"
                  >
                    <div className="home">My Listings</div>
                  </Link>
                )}
                <Link
                  className={`navlink${
                    isRouteActive("/pricing") ? "-active" : ""
                  }`}
                  to="/pricing"
                >
                  <div className="home">Pricing</div>
                </Link>
                <Link
                  className={`navlink${isRouteActive("/faq") ? "-active" : ""}`}
                  to="/faq"
                >
                  <div className="home">FAQ</div>
                </Link>
                <Link
                  className={`navlink${
                    isRouteActive("/contact") ? "-active" : ""
                  }`}
                  to="/contact"
                >
                  <div className="home">Contact Us</div>
                </Link>
                {currentUser !== null && currentUser !== undefined && (
                  <NavDropdown
                    className="position-relative navNotifications"
                    align="end"
                    title={
                      <div
                        onClick={() => {
                          if (page === 1) {
                            getNotifications();
                            setHasMore(true);
                          } else {
                            setHasMore(true);
                            setPage(1);
                          }
                        }}
                      >
                        {notificationCount > 0 && (
                          <div className="notificationNum">
                            {notificationCount}
                          </div>
                        )}
                        <BellIcon />
                      </div>
                    }
                    id="basic-nav-dropdown"
                  >
                    <div className="w-100">
                      <div className="heading w-100 text-center">
                        Notifications
                      </div>
                      {notificationCount > 0 && (
                        <div
                          onClick={() => {
                            setHasMore(true);
                            handleMarkAllAsRead();
                          }}
                          className="text-end m-2"
                          style={{ width: "95%", cursor: "pointer" }}
                        >
                          Mark all as read
                        </div>
                      )}
                      {notifications?.length === 0 && (
                        <div className="text-center p-5">
                          <BellIconBig />
                          <p style={{ whiteSpace: "nowrap" }} className="mt-3">
                            No Notification Yet
                          </p>
                        </div>
                      )}
                    </div>
                    {/* <NavDropdown.Divider /> */}
                    {notifications?.length > 0 && (
                      <div
                        ref={containerRef}
                        className="notifications-container"
                        onScroll={handleScroll}
                      >
                        <InfiniteScroll
                          dataLength={notifications.length}
                          next={loadmore}
                          hasMore={hasMore}
                        >
                          {notifications.map((data, index) => {
                            return (
                              <NavDropdown.Item
                                key={index}
                                className="mb-1 py-2"
                                style={{
                                  backgroundColor:
                                    data.isRead === false ? "#ededed" : "white",
                                  whiteSpace: "wrap",
                                }}
                              >
                                <div
                                  onClick={() => {
                                    if (data.isRead === false) {
                                      setHasMore(true);
                                      handleUpdateNotification(data._id);
                                    }
                                    if (data?.property) {
                                      navigate(
                                        `/property-details/${data.property}`
                                      );
                                    }
                                  }}
                                >
                                  <div className="title">{data.title}</div>
                                  <div className="date">
                                    {moment(data.createdAt).format(
                                      "DD MMM YYYY, hh:mm:s"
                                    )}
                                  </div>
                                </div>
                              </NavDropdown.Item>
                            );
                          })}
                        </InfiniteScroll>
                      </div>
                    )}
                  </NavDropdown>
                )}
                {!isLoading && (
                  <>
                    {currentUser ? (
                      <ProfileDropdown
                        getAllWorkspaces={getAllWorkspaces}
                        allWorkspaces={allWorkspaces}
                        currentUser={currentUser}
                        handleLogout={handleLogout}
                        setWorkspacePopup={setWorkspacePopup}
                      />
                    ) : (
                      <>
                        {!sessionStorage.getItem("currentUser") && (
                          <>
                            <Link className="navlink" to="/login">
                              <div className="home">Login</div>
                            </Link>
                            <Link className="" to="/signup">
                              <button className="btnGreen">Free Trial</button>
                            </Link>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* ====== Workspace Setting Popup ======= */}
      <WorkspaceSettingPopup
        workspacePopup={workspacePopup}
        setWorkspacePopup={setWorkspacePopup}
        hide={() => setWorkspacePopup(null)}
        currentUser={currentUser}
        getAllWorkspaces={getAllWorkspaces}
      />
    </>
  );
};

const ProfileDropdown = ({
  getAllWorkspaces,
  allWorkspaces,
  currentUser,
  handleLogout,
  setWorkspacePopup,
}) => {
  const navigate = useNavigate();
  const {
    currentWorkspace,
    setCurrentWorkspace,
    handleSelectCurrentWorkspace,
  } = useContext(User);
  return (
    <NavDropdown
      align="end"
      className="profileDropdown _customprofileClass"
      title={
        <div
          role="button"
          onClick={() => {
            const _customprofileClass = document?.querySelector(
              "._customprofileClass"
            );
            const show = _customprofileClass
              ?.querySelector(".dropdown-menu")
              ?.classList?.contains("show");
            if (!show) getAllWorkspaces({ query: null });
          }}
          className="d-flex justify-content-center align-items-center"
        >
          <div>
            <img
              src={currentUser.photo || defaultAvtar}
              width={"41px"}
              height={"40px"}
              alt=""
              style={{
                borderRadius: "50%",
                objectFit: "cover",
              }}
            />
          </div>
          <div className="ms-2" style={{ fontSize: "24px" }}>
            {currentUser.name}
          </div>
        </div>
      }
      id="basic-nav-dropdown"
    >
      <NavDropdown.Item className="_item" href="/profile">
        Profile
      </NavDropdown.Item>
      <hr className="mt-2" />
      <div className="dropdownWorkspaceArea">
        <h6 className="mb-1 titleTop">Workspaces</h6>
        {allWorkspaces.loading
          ? "Loading..."
          : allWorkspaces?.data?.length === 0
          ? "no data"
          : allWorkspaces?.data?.map((item, i) => (
              <div
                key={i}
                className="workspaceList d-flex align-items-center w-100"
              >
                <span
                  role="button"
                  onClick={() => handleSelectCurrentWorkspace(item)}
                  className="profileImg"
                >
                  <img src={item?.image_url || defaultAvtar} />
                </span>
                <div className="d-flex flex-column ms-2">
                  <div className="d-flex align-items-center">
                    <span
                      role="button"
                      onClick={() => handleSelectCurrentWorkspace(item)}
                      className="title"
                    >
                      {item.name}
                    </span>
                    {item._id === currentWorkspace?._id ? (
                      <span className="ms-2 active">
                        <CheckIcon />
                      </span>
                    ) : null}
                  </div>
                  <span
                    role="button"
                    onClick={() => setWorkspacePopup(item._id)}
                    className="option"
                  >
                    Settings & Members
                  </span>
                </div>
              </div>
            ))}
        <h6
          role="button"
          onClick={() => navigate("/all-workspaces")}
          className="mb-0 mt-2 titleBottom"
        >
          All Workspaces
        </h6>
      </div>
      <hr className="mb-2" />
      <NavDropdown.Item
        className="_item"
        href="#"
        onClick={() => handleLogout()}
      >
        Logout
      </NavDropdown.Item>
    </NavDropdown>
  );
};

const WorkspaceSettingPopup = ({
  workspacePopup,
  hide,
  currentUser,
  getAllWorkspaces,
  setWorkspacePopup
}) => {
  const [status, setStatus] = useState("active");
  const [workspaceTitle, setWorkspaceTitle] = useState({
    value: "",
    updatedValue: "",
    showButtons: false,
  });
  const [inviteMember, setInviteMember] = useState({
    email: "",
    role: "member",
  });
  const [inviteMemberList, setInviteMemberList] = useState([]);
  const [workspaceDetail, setWorkspaceDetail] = useState({
    data: null,
    loading: false,
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (workspacePopup) getWorkspaceDetail();
  }, [workspacePopup]);

  const getWorkspaceDetail = async () => {
    try {
      setWorkspaceDetail((prev) => ({ data: null, loading: true }));
      const { data } = await AxiosRequest.get(
        `/workspace/current-work-spaces?id=${workspacePopup}`
      );
      setWorkspaceDetail((prev) => ({ ...prev, data: data.data }));
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
    } finally {
      setWorkspaceDetail((prev) => ({ ...prev, loading: false }));
    }
  };

  const _filterMemberList = useMemo(() => {
    if (!workspaceDetail.loading && workspaceDetail.data !== null) {
      const activeList =
        workspaceDetail.data.workspace.members.length > 0
          ? workspaceDetail.data.workspace.members.filter(
              (m) => m.status === "active"
            )
          : [];
      const pendingList =
        workspaceDetail.data.workspace.members.length > 0
          ? workspaceDetail.data.workspace.members.filter(
              (m) => m.status === "pending"
            )
          : [];
      const workspaceUserAdmin = {
        _id: workspaceDetail?.data?.workspace?.user,
        name: workspaceDetail?.data?.user?.name,
        email: workspaceDetail?.data?.user?.email,
        image_url: workspaceDetail?.data?.user?.image || null,
        role: "admin",
        status: "active",
        joinDate: workspaceDetail?.data?.workspace?.createdAt || null,
        isWorkspaceSuperAdmin: true,
        // isLoggedInUser = ""
      };
      const _activeList = [workspaceUserAdmin, ...activeList];
      return { active: _activeList || [], pending: pendingList || [] };
    }
    return { active: [], pending: [] };
  }, [workspaceDetail, status]);

  const activeAdminsCount = useMemo(() => {
    const res =
      _filterMemberList?.active.length > 0
        ? _filterMemberList.active.filter((m) => m.role === "admin")
        : [];
    return res.length || 0;
  }, [_filterMemberList]);

  const isLoginUserWorkspaceAdmin = useMemo(() => {
    if (_filterMemberList.active.length > 0) {
      const check = _filterMemberList.active.find(
        (m) => m.email === currentUser.email
      );
      return check?.role === "admin" ? true : false;
    }
    return false;
  }, [_filterMemberList, currentUser]);

  const handleRemoveMemberFromInviteList = (item) => {
    const newList = inviteMemberList.filter((m) => m.email !== item.email);
    setInviteMemberList([...newList]);
  };

  const handleAddMembertoInviteList = () => {
    if (isLoginUserWorkspaceAdmin) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (inviteMemberList.find((m) => m.email === inviteMember.email)) {
        toast.error("email already added");
        return;
      }
      if (
        inviteMember.email.trim() !== "" &&
        emailRegex.test(inviteMember.email.trim())
      ) {
        setInviteMemberList((prev) => [...prev, inviteMember]);
        setInviteMember({ email: "", role: "member" });
      } else {
        toast.error("Please enter a valid email address.");
      }
    }
  };

  const handleInviteMembers = async (e) => {
    try {
      e.target.disabled = true;
      e.target.innerHTML = "Please wait";
      const { data } = await AxiosRequest.post(`/workspace/invite-member`, {
        members: [...inviteMemberList],
        id: workspacePopup,
      });
      toast.success(data.message);
      setInviteMemberList([]);
      getWorkspaceDetail();
    } catch (err) {
      toast.error(
        "You cannot invite your own email address." ||
          err?.response?.data?.message ||
          err?.message
      );
    } finally {
      e.target.disabled = false;
      e.target.innerHTML = "Invite";
    }
  };

  const handleChangeRole = async ({ e, role, member }) => {
    try {
      e.target.disabled = true;
      e.target.innerHTML = "Please wait";
      const { data } = await AxiosRequest.post(`/workspace/member-update`, {
        email: member.email,
        id: workspacePopup,
        role: role.toLowerCase(),
        status: member.status,
      });
      toast.success(data.message);
      getWorkspaceDetail();
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
    } finally {
      e.target.disabled = false;
      e.target.innerHTML = role;
    }
  };

  const handleRevokeMember = async ({ e, member }) => {
    try {
      e.target.disabled = true;
      e.target.innerHTML = "Please wait";
      const { data } = await AxiosRequest.post(`/workspace/member-update`, {
        email: member.email,
        id: workspacePopup,
        role: member.role,
        status: "revoke",
      });
      toast.success(data.message);
      getWorkspaceDetail();
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
    } finally {
      e.target.disabled = false;
      e.target.innerHTML = "Remove from workspace";
    }
  };

  // ========
  useEffect(() => {
    if (!workspaceDetail.loading && workspaceDetail.data !== null) {
      const title = workspaceDetail?.data?.workspace.name || "";
      setWorkspaceTitle((prev) => ({
        showButtons: false,
        value: title,
        updatedValue: title,
      }));
    }
  }, [workspaceDetail]);

  const handleWorkspaceTitleInputChange = (e) => {
    if (isLoginUserWorkspaceAdmin) {
      setWorkspaceTitle((prev) => ({
        ...prev,
        showButtons: true,
        updatedValue: e.target.value,
      }));
    }
  };

  const handleUpdateWorkspaceTitle = async (e) => {
    try {
      e.target.disabled = true;
      e.target.innerHTML = "Please wait";
      const { data } = await AxiosRequest.post(`/workspace/update`, {
        id: workspacePopup,
        name: workspaceTitle.updatedValue,
      });
      toast.success(data.message);
      getWorkspaceDetail();
      getAllWorkspaces({ query: null });
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
    } finally {
      e.target.disabled = false;
      e.target.innerHTML = "Update";
    }
  };

  const handleCancelWorkspaceTitle = () => {
    setWorkspaceTitle((prev) => ({
      ...prev,
      showButtons: false,
      updatedValue: prev.value,
    }));
  };

  const roleDropdownUi = (item) => {
    const _disable = (role) => {
      const res =
        item.role.toLowerCase() === role.toLowerCase() ||
        (item.status === "active" &&
          item.role.toLowerCase() === "admin" &&
          activeAdminsCount < 2);
      return res;
    };
    return (
      <Dropdown className="dropdownRole">
        <Dropdown.Toggle
          variant="white"
          id="dropdown-basic"
          className="text-capitalize"
        >
          {item.role}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {["Member", "Admin"].map((role, i) => (
            <Dropdown.Item
              disabled={_disable(role)}
              role="button"
              onClick={(e) =>
                !_disable(role)
                  ? handleChangeRole({ e, role, member: item })
                  : null
              }
              key={i}
              className="text-capitalize"
            >
              {role}
            </Dropdown.Item>
          ))}
          <hr className="mb-2" />
          <Dropdown.Item
            className="text-danger fw600"
            style={{ opacity: item.email === currentUser.email ? "0.5" : "1" }}
            role="button"
            disabled={
              !isLoginUserWorkspaceAdmin || item.email === currentUser.email
            }
            onClick={(e) => {
              if (
                isLoginUserWorkspaceAdmin &&
                item.email !== currentUser.email
              ) {
                handleRevokeMember({ e, member: item });
              }
            }}
          >
            Remove from workspace
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  return (
    <Modal
      show={workspacePopup}
      backdrop="static"
      size="lg"
      onHide={hide}
      className="modalCustom2"
    >
      <Modal.Header closeButton>Workspace settings</Modal.Header>
      <Modal.Body>
        <div className="d-md-flex  flex-col align-items-center justify-content-between grayBox upgradePlanArea">
          <div className="me-2">
            <span className="title">Upgrade to Professional</span>
            <p className="desc">
              Unlock 2000 Credits, Description & Social Description, Showcase
              Video, Presentation, 24x7 Support
            </p>
          </div>
          <button 
            style={{ minWidth: "max-content" }} 
            className="btn btnGreen" 
            onClick={() => {
              navigate("/pricing");
              setWorkspacePopup(null);
            }}
          >
            Upgrade Plan
          </button>
        </div>
        <div className="mt-3 mb-4">
          <div className="heading4-parent col-12">
            <div className="heading-2-wrapper ps-1">
              <b className="home">Workspace name</b>
            </div>
            <div className="w-100">
              {/* ============== */}
              <div className="d-flex align-items-center justify-content-between flex-wrap">
                <div className="customInviteMemberInputArea col">
                  <input
                    disabled={!isLoginUserWorkspaceAdmin}
                    className="w-100"
                    placeholder="John Dao's Workspace"
                    type="text"
                    value={workspaceTitle.updatedValue}
                    onChange={handleWorkspaceTitleInputChange}
                  />
                </div>
                {workspaceTitle.showButtons && (
                  <div className="d-flex flex-wrap">
                    <button
                      onClick={(e) => handleUpdateWorkspaceTitle(e)}
                      className="btn btnGreen smallBtn "
                      type="button"
                    >
                      Update
                    </button>
                    <button
                      onClick={handleCancelWorkspaceTitle}
                      className="btn btnPrimary smallBtn"
                      type="button"
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </div>
              {/* ========== */}

              <p className="fw600 text-start mt-2 ps-1">
                E.g. your team or company name.
              </p>
            </div>
          </div>
          <div className="heading4-parent col-12">
            <div className="heading-2-wrapper ps-1">
              <b className="home">Invite others to this workspace</b>
            </div>
            <p className="fw600 text-start mb-2 ps-1">
              Invite by email address
            </p>
            <div className="w-100">
              <div className="d-flex align-items-start justify-content-between flex-wrap">
                <div className="customInviteMemberInputArea col">
                  {inviteMemberList?.length > 0 ? (
                    <div
                      // style={{
                      //   boxShadow: "#00000036 0px 1px 6px 1px",
                      //   borderRadius: "4px",
                      //   overflow: "hidden",
                      //   padding: "6px",
                      //   marginBottom: "8px"
                      // }}
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="mList mb-2">
                        {inviteMemberList.map((item, i) => (
                          <div className="item" key={i}>
                            <span className="email1 me-2">{item.email}</span>
                            <span className="role2 me-2">{item.role}</span>
                            <span
                              onClick={() =>
                                handleRemoveMemberFromInviteList(item)
                              }
                              className="btnRemove3"
                            >
                              <CloseIcon />
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : null}
                  <div>
                    <input
                      disabled={!isLoginUserWorkspaceAdmin}
                      className=""
                      placeholder="Add People"
                      type="text"
                      value={inviteMember.email}
                      onChange={(e) => {
                        if (isLoginUserWorkspaceAdmin)
                          setInviteMember({
                            ...inviteMember,
                            email: e.target.value,
                          });
                      }}
                    />
                    <select
                      disabled={!isLoginUserWorkspaceAdmin}
                      value={inviteMember.role}
                      onChange={(e) => {
                        if (isLoginUserWorkspaceAdmin)
                          setInviteMember({
                            ...inviteMember,
                            role: e.target.value,
                          });
                      }}
                    >
                      {["member", "admin"].map((role, i) => (
                        <option
                          className="text-capitalize"
                          key={i}
                          value={role}
                        >
                          {role}
                        </option>
                      ))}
                    </select>
                    <button
                      disabled={!isLoginUserWorkspaceAdmin}
                      onClick={handleAddMembertoInviteList}
                      className="btnGreen sm smallBtn ms-2"
                      type="button"
                    >
                      Add
                    </button>
                  </div>
                </div>
                {inviteMemberList.length > 0 ? (
                  <button
                    onClick={(e) => handleInviteMembers(e)}
                    className="btn btnGreen smallBtn"
                    type="button"
                  >
                    Invite
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {/* ========= */}
        <Row>
          <Col>
            <Nav
              className="customTab2 mb-1"
              variant="pills"
              activeKey={status}
              onSelect={(key) => setStatus(key)}
            >
              <Nav.Item>
                <Nav.Link className="navLink" eventKey="active">
                  <span className="me-2">
                    <MembersIcon />
                  </span>
                  <span>
                    Active Members ({_filterMemberList?.active?.length || 0})
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="navLink" eventKey="pending">
                  <span className="me-2">
                    <MessageIcon />
                  </span>
                  <span>
                    Invitations ({_filterMemberList?.pending?.length || 0})
                  </span>
                </Nav.Link>
              </Nav.Item>
            </Nav>
            {status === "active" && (
              <Table bordered className="memberTable">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th className="text-start">Join date</th>
                    <th className="text-center">Role</th>
                  </tr>
                </thead>
                <tbody>
                  {workspaceDetail.loading ? (
                    <tr>
                      <td className="text-center" colSpan={3}>
                        <span>
                          <Spinner size="sm" /> Loading
                        </span>
                      </td>
                    </tr>
                  ) : _filterMemberList[status]?.length === 0 ? (
                    <tr>
                      <td className="text-center" colSpan={3}>
                        <strong>No member found!</strong>
                      </td>
                    </tr>
                  ) : (
                    _filterMemberList[status].map((item, index) => (
                      <React.Fragment key={index}>
                        <tr>
                          <td>
                            <div className="memberArea d-flex align-items-center">
                              <span className="profileImg">
                                <img src={item?.image_url || defaultAvtar} />
                              </span>
                              <div className="d-flex flex-column ms-2">
                                <span className="title">
                                  {item?.name
                                    ? `${item?.name}${
                                        item.email === currentUser.email
                                          ? " (you)"
                                          : ""
                                      }`
                                    : "-"}
                                </span>
                                <span className="title2">{item.email}</span>
                              </div>
                            </div>
                          </td>
                          <td className="text-start">
                            <span className="date">
                              {item.joinDate
                                ? moment(item.joinDate).format("MMM DD, YYYY")
                                : "-"}
                            </span>
                          </td>
                          <td className="text-center">
                            {isLoginUserWorkspaceAdmin
                              ? roleDropdownUi(item)
                              : item.role}
                          </td>
                        </tr>
                      </React.Fragment>
                    ))
                  )}
                </tbody>
              </Table>
            )}
            {status === "pending" && (
              <Table bordered className="memberTable">
                <thead>
                  <tr>
                    <th>Email</th>
                    <th className="text-start">Last Sent</th>
                    <th className="text-center">Role</th>
                  </tr>
                </thead>
                <tbody>
                  {workspaceDetail.loading ? (
                    <tr>
                      <td className="text-center" colSpan={3}>
                        <span>
                          <Spinner size="sm" /> Loading
                        </span>
                      </td>
                    </tr>
                  ) : _filterMemberList[status]?.length === 0 ? (
                    <tr>
                      <td className="text-center" colSpan={3}>
                        <strong>No member found!</strong>
                      </td>
                    </tr>
                  ) : (
                    _filterMemberList[status].map((item, index) => (
                      <tr key={index}>
                        <td>
                          <div className="memberArea d-flex align-items-center">
                            <div className="d-flex flex-column ms-1">
                              <span className="title2">{item.email}</span>
                            </div>
                          </div>
                        </td>
                        <td className="text-start">
                          <span className="date">
                            {item.expiredAt
                              ? moment(item.expiredAt)
                                  .subtract(10, "minutes")
                                  .format("MMM DD, YYYY")
                              : "-"}
                          </span>
                        </td>
                        <td className="text-center">
                          {isLoginUserWorkspaceAdmin
                            ? roleDropdownUi(item)
                            : item.role}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            )}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        {/* <button
          className="btn btnBordered"
        // onClick={closeModal}
        >
          Skip
        </button> */}
        <button className="btn btnGreen" onClick={hide}>
          Done
        </button>
      </Modal.Footer>
    </Modal>
  );
};
