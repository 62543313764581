import React, { createContext, useContext, useEffect, useState } from "react";
import firebaseApp from "../utils/init-firebase";
import { getAuth } from "firebase/auth";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  onAuthStateChanged,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  confirmPasswordReset,
  updatePassword,
} from "firebase/auth";

const auth = getAuth(firebaseApp);

const AuthContext = createContext({
  currentUser: null,
  signInWithGoogle: () => Promise,
  login: () => Promise,
  register: () => Promise,
  logout: () => Promise,
  forgotPassword: () => Promise,
  resetPassword: () => Promise,
});

export const useAuth = () => useContext(AuthContext);

export default function AuthContextProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user ? user : null);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function register(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  function forgotPassword(email) {
    return sendPasswordResetEmail(auth, email, {
      url: `${process.env.REACT_APP_BASE_URL}/login`,
    });
  }

  function resetPassword(oobCode, newPassword) {
    return confirmPasswordReset(auth, oobCode, newPassword);
  }

  function logout() {
    return signOut(auth);
  }

  function signInWithGoogle() {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider);
  }

  function changePassword(password) {
    if (auth.currentUser) {
      return updatePassword(auth.currentUser, password);
    } else {
      return Promise.reject(new Error("No user is currently logged in."));
    }
  }

  const handleAuthError = (error) => {
    let errorMessage = "An unexpected error occurred. Please try again later.";
    switch (error.code) {
      case "auth/invalid-email":
        errorMessage = "The email address is invalid.";
        break;
      case "auth/user-disabled":
        errorMessage = "The user associated with this email has been disabled.";
        break;
      case "auth/user-not-found":
        errorMessage = "No user found with this email address.";
        break;
      case "auth/wrong-password":
        errorMessage = "Wrong password. Please try again.";
        break;
      case "auth/email-already-in-use":
        errorMessage =
          "The email address is already in use by another account.";
        break;
      case "auth/weak-password":
        errorMessage = "The password is too weak.";
        break;
      case "auth/cancelled-popup-request":
        errorMessage =
          "This operation has been cancelled due to another conflicting popup being opened.";
        break;
      case "auth/popup-closed-by-user":
        errorMessage =
          "The popup was closed before finalizing the operation. Please try again.";
        break;
      case "auth/invalid-login-credentials":
        errorMessage = "Authentication Failed";
        break;
      case "auth/expired-action-code":
        errorMessage =
          "The action code has expired. Please reset your password again.";
        break;
      case "auth/invalid-action-code":
        errorMessage =
          "The action code is invalid. Please check the link or request a new one.";
        break;
      case "auth/too-many-requests":
        errorMessage = "We have blocked all requests from this device due to unusual activity. Try again later.";
        break;
      // Add more cases as needed
      default:
        break;
    }
    return errorMessage; // You might want to replace this with a more user-friendly UI update
  };

  const value = {
    currentUser,
    signInWithGoogle,
    login,
    register,
    logout,
    forgotPassword,
    resetPassword,
    changePassword,
    handleAuthError,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
