import BannerHeader from "../../components/BannerHeader";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";

const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <div className="pricing">
        <BannerHeader heading="Privacy Policy" />
        <div className="container py-5">
          <div className="mb-5">
            <div className="font24Black text-start mb-3">Introduction</div>
            <p className="font20BlkReg text-start">
              These Website Standard Terms and Conditions written on this
              webpage shall manage your use of our website, Webiste Name
              accessible at Website.com.
            </p>
            <p className="font20BlkReg text-start">
              These Terms will be applied fully and affect to your use of this
              Website. By using this Website, you agreed to accept all terms and
              conditions written in here. You must not use this Website if you
              disagree with any of these Website Standard Terms and Conditions.
            </p>
          </div>

          <div className="mb-5">
            <div className="font24Black text-start mb-3">
              Intellectual Property Rights
            </div>
            <p className="font20BlkReg text-start">
              Other than the content you own, under these Terms, Company Name
              and/or its licensors own all the intellectual property rights and
              materials contained in this Website.
            </p>
            <p className="font20BlkReg text-start">
              You are granted limited license only for purposes of viewing the
              material contained on this Website.
            </p>
          </div>

          <div className="mb-5">
            <div className="font24Black text-start mb-3">
              Limitation of liability
            </div>
            <p className="font20BlkReg text-start">
              In no event shall Company Name, nor any of its officers, directors
              and employees, shall be held liable for anything arising out of or
              in any way connected with your use of this Website whether such
              liability is under contract. Company Name, including its officers,
              directors and employees shall not be held liable for any indirect,
              consequential or special liability arising out of or in any way
              related to your use of this Website.
            </p>
          </div>

          <div className="mb-5">
            <div className="font24Black text-start mb-3">Severability</div>
            <p className="font20BlkReg text-start">
              If any provision of these Terms is found to be invalid under any
              applicable law, such provisions shall be deleted without affecting
              the remaining provisions herein.
            </p>
          </div>

          <div className="mb-5">
            <div className="font24Black text-start mb-3">
              Variation of Terms
            </div>
            <p className="font20BlkReg text-start">
              Company Name is permitted to revise these Terms at any time as it
              sees fit, and by using this Website you are expected to review
              these Terms on a regular basis.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
