import { createContext, useContext, useMemo, useState } from 'react';

const tourState = {
  run: false,
  stepIndex: 0,
  steps: [],
  tourActive: false,
};

export const TourContext = createContext({
  state: tourState,
  setState: () => undefined,
});
TourContext.displayName = 'TourContext';

export function TourProvider(props) {
  const [state, setState] = useState(tourState);

  const value = useMemo(
    () => ({
      state,
      setState,
    }),
    [setState, state],
  );

  return <TourContext.Provider value={value} {...props} />;
}

export function useTourContext() {
  const context = useContext(TourContext);

  if (!context) {
    throw new Error('useTourContext must be used within a TourProvider');
  }

  return context;
}
