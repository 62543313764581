import pptMainImg from "../../assets/images/pptMainImg.png";
import ContactUsImg from "../../assets/images/ContactUs.png";
import { Breadcrumb, Card, Col, NavDropdown, Row } from "react-bootstrap";
import ContentEditable from "react-contenteditable";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import "jspdf-autotable";
import LogoImage from "../../assets/images/Logo.png";
import KitchenPlaceholder from "../../assets/images/KitchenPlacholder.svg";
import HospitalPlaceholder from "../../assets/images/HospitalPlaceholder.png";
import SchoolPlaceholder from "../../assets/images/SchoolPlaceholder.png";
import SuperMarketPlaceholder from "../../assets/images/supermarketImg1.png";
import {
  BathIcon,
  BedRoomIcon,
  DollarIcon,
  ExteriorIcon,
  KitchenIcon,
  LivingRoomIcon,
  ParkingIcon,
  PropertySizeIcon,
  TranscationTypeIcon,
} from "../../shared/Icons";
import PropertyImagePlaceholder from "../../assets/images/propertyimageplaceholder.png";
import jsPDF from "jspdf";
import axios from "axios";
import html2canvas from "html2canvas";
import AxiosRequest from "../../utils/AxiosRequest";
import toast from "react-hot-toast";
import pptxgen from "pptxgenjs";
import { processSlide } from "../../utils/helper";

const Template1 = ({
  propertyDetails,
  isEdit,
  setIsEdit,
  imageSrc,
  nearByPlaces,
  handleSaveDesc,
  editedDescription,
  handleDescriptionChange,
  generateStars,
  coordinates,
  mapContainerStyle,
  currentUser,
  setIsLoading,
  staticMapURL,
  template,
  setTemplate,
  params,
  isGenerationOwner,
}) => {
  const getIconByName = (name) => {
    if (name === "Exterior") {
      return <ExteriorIcon />;
    } else if (name === "Kitchen") {
      return <KitchenIcon />;
    } else if (name === "Living Room") {
      return <LivingRoomIcon />;
    } else if (name === "Bedroom") {
      return <BedRoomIcon />;
    } else if (name === "Garden") {
      return <LivingRoomIcon />;
    }
  };

  const _handleGeneratePDF = async () => {
    try {
      if (currentUser?.credits > 0) {
        setIsLoading(true);
        const slides = document.getElementsByClassName("newPptSlide");

        // Create a jsPDF instance
        const pdf = new jsPDF();
        // Function to loop through each slide and add it to the PDF
        async function addSlideToPDF(index) {
          if (index < slides.length) {
            const slide = slides[index];
            await processSlide(slide);
            const canvas = await html2canvas(slide);
            // const imageData = canvas.toDataURL('image/jpeg', 1.0);
            let imgData;
            // if (index === slides.length - 1) {
            //   const response = await fetch(staticMapURL);
            //   const blob = await response.blob();
            //   imgData = URL.createObjectURL(blob);
            // } else {
            imgData = canvas.toDataURL("image/jpeg", 1.0);
            // }
            // Set PDF page height as per slide height
            const pdfHeight =
              (pdf.internal.pageSize.getWidth() / canvas.width) * canvas.height;

            // Add a new page for slides starting from the second slide
            pdf.addPage([pdf.internal.pageSize.getWidth(), pdfHeight]);
            if (imgData) {
              pdf.addImage(
                imgData,
                "JPEG",
                0,
                0,
                pdf.internal.pageSize.getWidth(),
                pdfHeight
              );
            }
            await addSlideToPDF(index + 1);

            // Process next slide
          } else {
            // Save the PDF
            pdf.deletePage(1);
            // pdf.deletePage(index);
            pdf.save(`${propertyDetails.name}.pdf`);
            toast.success("PDF Download Successfully.");
            // window.location.reload();
            setIsLoading(false);
          }
        }

        // Start adding slides to the PDF
        await addSlideToPDF(0);
        // await AxiosRequest.get("/property/updateUserCredits");
      } else {
        toast.error("Please upgrade your plan for exporting PDF");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error generating PDF:", error);
    }
  };

  const generatePowerPoint = async () => {
    try {
      if (currentUser.credits > 0) {
        setIsLoading(true);
        if (document.getElementsByClassName("newPptSlide")) {
          const slideArray = document.getElementsByClassName("newPptSlide");
          const pptx = new pptxgen();
          for (let i = 0; i < slideArray.length; i++) {
            const slide = pptx.addSlide();
            await processSlide(slideArray[i]);
            let canvas;
            // if (i === slideArray.length - 1) {
            //   const response = await fetch(staticMapURL);
            //   const blob = await response.blob();
            //   const reader = new FileReader(); // Define the reader here
            //   const base64Image = await new Promise((resolve) => {
            //     reader.onloadend = () => resolve(reader.result);
            //     reader.readAsDataURL(blob);
            //   });
            //   canvas = base64Image;
            // } else {
            const data = await html2canvas(slideArray[i], { dpi: 300 });
            canvas = data.toDataURL();
            // }
            if (canvas) {
              slide.addImage({
                data: canvas,
                w: "100%",
                h: "100%",
                x: 0,
                y: 0,
              });
            }
          }
          pptx.writeFile({ fileName: `${propertyDetails.name}` });
        }
        // await AxiosRequest.get("/property/updateUserCredits");
        toast.success("Presentation downloaded successfully");
        setTimeout(() => {
          setIsLoading(false);
          // window.location.reload();
        }, [1000]);
      } else {
        toast.error("Please upgrade your plan for export presentation");
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Failed to export as Presentation. Please try again later.");
      console.error("Capture Error:", error);
    }
  };

  const handleGeneratePDF = async () => {
    try {
      if (currentUser?.credits > 0) {
        setIsLoading(true);
        const slides = document.getElementsByClassName("newPptSlide");

        // const allImages = [];
        // for (let slide of slides) {
        //     const imagesInSlide = slide.querySelectorAll("img");
        //     imagesInSlide.forEach(image => {
        //         allImages.push(image?.src);
        //     });
        // }
        // console.log("allImages", allImages)

        const pdf = new jsPDF({ unit: "px", format: "a4" });

        async function addSlideToPDF(index) {
          if (index < slides.length) {
            const slide = slides[index];
            await processSlide(slide);
            const canvas = await html2canvas(slide, { scale: 2 });

            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const imgHeight = (canvas.height * pdfWidth) / canvas.width;
            const topMargin = 20;

            if (index !== 0) {
              pdf.addPage();
            }

            pdf.setFillColor("#fff2f3");
            pdf.rect(0, 0, pdfWidth, pdfHeight, "F");

            // const verticalPosition = topMargin + (pdfHeight - imgHeight * 2) / 2;
            let verticalPosition = topMargin;
            if (imgHeight + topMargin * 2 <= pdfHeight) {
              verticalPosition = (pdfHeight - imgHeight) / 2;
            }

            if (canvas) {
              pdf.addImage(
                canvas.toDataURL("image/jpeg", 1.0),
                "JPEG",
                0,
                verticalPosition / 2,
                pdfWidth,
                imgHeight
              );
            }
            await addSlideToPDF(index + 1);
          } else {
            pdf.save(`${propertyDetails.name}.pdf`);
            toast.success("PDF Download Successfully.");
            setIsLoading(false);
          }
        }

        await addSlideToPDF(0);
        // await AxiosRequest.get("/property/updateUserCredits");
      } else {
        toast.error("Please upgrade your plan for exporting PDF");
        setIsLoading(false);
      }
    } catch (error) {
      console.log("Error", error);

      setIsLoading(false);
      toast.error("Failed to export as PDF. Please try again later.");
      console.error("Error generating PDF:", error);
    }
  };

  return (
    <div className="ppt py-5">
      <div className="container">
        <div className="d-block d-md-flex justify-content-between align-items-center">
          {params && params.id && (
            <Breadcrumb className="breadcrumbCustom fs20">
              <Breadcrumb.Item href={`/property-details/${params.id}`}>
                Property Details
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Preview Presentation</Breadcrumb.Item>
            </Breadcrumb>
          )}

          {/* {nearByPlaces && nearByPlaces.length > 0 && staticMapURL && ( */}
          <div>
            <NavDropdown title={template} className="btnGreen">
              <NavDropdown.Item
                href="#"
                onClick={() => setTemplate(process.env.REACT_APP_TEMPLATE_1)}
              >
                {process.env.REACT_APP_TEMPLATE_1}
              </NavDropdown.Item>
              <NavDropdown.Item
                href="#"
                onClick={() => setTemplate(process.env.REACT_APP_TEMPLATE_2)}
              >
                {process.env.REACT_APP_TEMPLATE_2}
              </NavDropdown.Item>
              <NavDropdown.Item
                href="#"
                onClick={() => setTemplate(process.env.REACT_APP_TEMPLATE_3)}
              >
                {process.env.REACT_APP_TEMPLATE_3}
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Export" className="btnGreen ms-4">
              <NavDropdown.Item href="#" onClick={generatePowerPoint}>
                Presentation
              </NavDropdown.Item>
              <NavDropdown.Item href="#" onClick={handleGeneratePDF}>
                PDF
              </NavDropdown.Item>
            </NavDropdown>
          </div>
          {/* )} */}
        </div>
      </div>
      {propertyDetails && (
        <>
          <div className="pptPage">
            <div className="container">
              {/* slide one start */}
              <div className="newPptSlide" style={{ position: "relative" }}>
                <div
                  className="row align-content-center justify-content-between mx-0"
                  style={{ height: "650px" }}
                >
                  <div
                    className="col-12 col-lg-6 p-50 d-flex"
                    style={{ height: "100%" }}
                  >
                    <div className="d-flex justify-content-between align-items-start flex-column">
                      <div>
                        <img src={LogoImage} alt="" />
                      </div>
                      <div>
                        <div className="font30BoldPPTRed">
                          {currentUser && currentUser.name}
                        </div>
                        {currentUser && currentUser.mobileNumber && (
                          <div className="font20BlackPPT">
                            Mobile No. : {currentUser.mobileNumber}
                          </div>
                        )}
                        <div className="font20BlackPPT">
                          Email ID : {currentUser && currentUser.email}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 px-0">
                    <div className="imgDiv">
                      <div
                        className="imgDiv_imgContainer"
                        style={{
                          backgroundImage: `url(${
                            propertyDetails?.coverImage
                              ? propertyDetails.coverImage
                              : pptMainImg
                          })`,
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center align-items-center w-100">
                  <div
                    className="propertyDetails"
                    style={{
                      height: "350px",
                      width: "350px",
                      position: "absolute",
                      top: 150,
                    }}
                  >
                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <div
                        style={{ maxWidth: "250px", wordBreak: "break-word" }}
                      >
                        <div className="newfont30WhitePPT">
                          {propertyDetails.name}
                        </div>
                        <div className="newfont20WhitePPT">
                          {propertyDetails.type},{propertyDetails.size.value}{" "}
                          {propertyDetails.size.unit}
                        </div>
                        <div className="newfont20WhitePPT">
                          Price: {propertyDetails?.priceSymbol}
                          {propertyDetails.price}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* slide two start */}
              <div className="newPptSlide p-50">
                <div className="d-flex justify-content-between align-items-start mb-5">
                  <div className="d-flex pptHeader w-100">
                    <div className="part"></div>
                    <div className="my-auto mx-2 w-90">Description</div>
                    {isEdit && isGenerationOwner && (
                      <div className="d-flex justify-content-end align-items-center me-2">
                        <button
                          className="btnGreen"
                          onClick={() => handleSaveDesc()}
                          style={{ height: "40px" }}
                        >
                          Save
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="font20BlackPPT">
                  {isGenerationOwner ? (
                    <ContentEditable
                      html={JSON.parse(editedDescription).replace(
                        /(\n\n)/g,
                        "<br/><br/>"
                      )}
                      onChange={handleDescriptionChange}
                      onClick={() => setIsEdit(true)}
                    />
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: JSON.parse(editedDescription).replace(
                          /(\n\n)/g,
                          "<br/><br/>"
                        ),
                      }}
                      onClick={() => setIsEdit(true)}
                    />
                  )}
                </div>
              </div>
              {/* slide three start */}
              <div className="newPptSlide p-50">
                <div className="d-flex justify-content-between align-items-start mb-5">
                  <div className="d-flex pptHeader w-100">
                    <div className="part"></div>
                    <div className="my-auto mx-2 w-90">Property Details</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-5">
                    <div className="d-flex justify-content-end mb-3">
                      {propertyDetails.isVideoGenerate ? (
                        <img
                          src={propertyDetails.propertyImages[0].images[0]}
                          alt=""
                          className="propertChildImage"
                        />
                      ) : (
                        <img
                          src={PropertyImagePlaceholder}
                          alt=""
                          className="propertChildImage"
                        />
                      )}
                    </div>
                    <div className="d-flex justify-content-start">
                      {propertyDetails.isVideoGenerate ? (
                        <img
                          src={propertyDetails.coverImage}
                          alt=""
                          className="propertyImage"
                        />
                      ) : (
                        <img
                          src={PropertyImagePlaceholder}
                          alt=""
                          className="propertyImage"
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-7">
                    <div className="row">
                      <div className="col-4">
                        <div className="d-flex justify-content-center align-items-center flex-column">
                          <div className="mb-1">
                            <BedRoomIcon />
                          </div>
                          <div className="font18BlackPPT">BedRooms</div>
                          <div className="font18BoldBlackPPT">
                            {propertyDetails.num_bedrooms || 3}
                          </div>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="d-flex justify-content-center align-items-center flex-column">
                          <div className="mb-1">
                            <BathIcon />
                          </div>
                          <div className="font18BlackPPT">Baths</div>
                          <div className="font18BoldBlackPPT">
                            {propertyDetails.num_baths || 3}
                          </div>
                        </div>
                      </div>
                      <div className="col-4 mb-5">
                        <div className="d-flex justify-content-center align-items-center flex-column">
                          <div className="mb-1">
                            <ParkingIcon />
                          </div>
                          <div className="font18BlackPPT">Parking</div>
                          <div className="font18BoldBlackPPT">
                            {propertyDetails.parking || 1}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row my-5">
                      {propertyDetails.isVideoGenerate &&
                        propertyDetails.propertyImages.map((data, index) => {
                          return (
                            <div className="col-4 mb-5" key={index}>
                              <div className="d-flex justify-content-center align-items-center flex-column">
                                <div className="mb-1">
                                  {getIconByName(data.name)}
                                </div>
                                <div className="font18BlackPPT">
                                  {data.name}
                                </div>
                                <div className="font18BoldBlackPPT">
                                  {data.length} x {data.width} {data.sizeUnit}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>

                    <div className="row mt-5">
                      <div className="col-4">
                        <div className="d-flex justify-content-center align-items-center flex-column">
                          <div className="mb-1">
                            <TranscationTypeIcon />
                          </div>
                          <div className="font18BlackPPT">Transcation Type</div>
                          <div className="font18BoldBlackPPT">
                            {propertyDetails.transactionType}
                          </div>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="d-flex justify-content-center align-items-center flex-column">
                          <div className="mb-1">
                            <PropertySizeIcon />
                          </div>
                          <div className="font18BlackPPT">Property Size</div>
                          <div className="font18BoldBlackPPT">
                            {propertyDetails.size.value}{" "}
                            {propertyDetails.size.unit}
                          </div>
                        </div>
                      </div>
                      <div className="col-4 mb-5">
                        <div className="d-flex justify-content-center align-items-center flex-column">
                          <div className="mb-1">
                            <DollarIcon />
                          </div>
                          <div className="font18BlackPPT">Property Price</div>
                          <div className="font18BoldBlackPPT">
                            {propertyDetails.priceSymbol}
                            {propertyDetails.price}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* New Slide Starte */}
              {propertyDetails.propertyImages.map((data, index) => {
                return (
                  <div className="newPptSlide p-50">
                    <div className="d-flex justify-content-between align-items-start mb-5">
                      <div className="d-flex pptHeader w-100">
                        <div className="part"></div>
                        <div className="my-auto mx-2 w-90">{data.name}</div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <img
                          className="roomImage"
                          src={data.images[0]}
                          alt=""
                        />
                      </div>
                      {propertyDetails?.presentation &&
                      propertyDetails.presentation.length > 0 ? (
                        <>
                          {propertyDetails.presentation
                            .filter(
                              (presentation) => presentation.type === data.name
                            )
                            .map((presentation, index) => (
                              <div className="col-7" key={index}>
                                <div className="font30BoldBlackPPT mb-4">
                                  {presentation.content.title}
                                </div>
                                <div
                                  className="font24BlackPPT mb-4"
                                  style={{ fontWeight: "400" }}
                                >
                                  {presentation.content.subtitle}
                                </div>
                                <div className="font20BlackPPT">
                                  {presentation.content.description}
                                </div>
                              </div>
                            ))}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                );
              })}

              {nearByPlaces.length > 0 && (
                <div
                  className={
                    nearByPlaces.some(
                      (data) =>
                        data.type === "restaurant" ||
                        data.type === "grocery_or_supermarket"
                    )
                      ? "newPptSlide p-50"
                      : "d-none"
                  }
                >
                  <div className="d-flex justify-content-between align-items-start">
                    <div className="d-flex pptHeader w-100">
                      <div className="part"></div>
                      <div className="my-auto mx-2 w-90">Restaurants</div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-start row mb-5">
                    <Row xs={1} md={2} lg={2} className="g-3">
                      {nearByPlaces.map((data, index) => {
                        return (
                          <>
                            {data.type === "restaurant" && (
                              <Col>
                                <Card className="d-flex justify-content-between flex-row Property_Cards">
                                  <Card.Img
                                    variant="top"
                                    className="Card_Image"
                                    src={
                                      imageSrc[index]
                                        ? imageSrc[index]
                                        : KitchenPlaceholder
                                    }
                                  />
                                  <Card.Body className="ms-3">
                                    <Card.Title className="font18BoldBlackPPT">
                                      {data.name}
                                    </Card.Title>
                                    <Card.Text>
                                      <span className="font16BoldBlackPPT">
                                        Address :-{" "}
                                      </span>
                                      <span className="font16BlackPPT">
                                        {data.vicinity}
                                      </span>
                                    </Card.Text>
                                    <Card.Text>
                                      <span className="font16BoldBlackPPT">
                                        Distance :-{" "}
                                      </span>
                                      <span className="font16BlackPPT">
                                        {data.distance} km
                                      </span>
                                    </Card.Text>
                                    <Card.Text>
                                      <div className="d-flex">
                                        <span className="font16BoldBlackPPT">
                                          Rating :-{" "}
                                        </span>
                                        <div className="Property_Cards_rating">
                                          <span>
                                            {generateStars(data.rating)}
                                          </span>
                                          <span className="font16BlackPPT ms-2">
                                            {data.user_ratings_total || 0}{" "}
                                            Review
                                          </span>
                                        </div>
                                      </div>
                                    </Card.Text>
                                  </Card.Body>
                                </Card>
                              </Col>
                            )}
                          </>
                        );
                      })}
                    </Row>
                  </div>

                  <div className="d-flex justify-content-between align-items-start">
                    <div className="d-flex pptHeader w-100">
                      <div className="part"></div>
                      <div className="my-auto mx-2 w-90">
                        Grocery or Supermarkert
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-start row">
                    <Row xs={1} md={2} lg={2} className="g-3">
                      {nearByPlaces.map((data, index) => {
                        return (
                          <>
                            {data.type === "grocery_or_supermarket" && (
                              <Col>
                                <Card className="d-flex justify-content-between flex-row Property_Cards">
                                  <Card.Img
                                    variant="top"
                                    className="Card_Image"
                                    src={
                                      imageSrc[index]
                                        ? imageSrc[index]
                                        : SuperMarketPlaceholder
                                    }
                                  />
                                  <Card.Body className="ms-3">
                                    <Card.Title className="font18BoldBlackPPT">
                                      {data.name}
                                    </Card.Title>
                                    <Card.Text>
                                      <span className="font16BoldBlackPPT">
                                        Address :-{" "}
                                      </span>
                                      <span className="font16BlackPPT">
                                        {data.vicinity}
                                      </span>
                                    </Card.Text>
                                    <Card.Text>
                                      <span className="font16BoldBlackPPT">
                                        Distance :-{" "}
                                      </span>
                                      <span className="font16BlackPPT">
                                        {data.distance} km
                                      </span>
                                    </Card.Text>
                                    <Card.Text>
                                      <div className="d-flex">
                                        <span className="font16BoldBlackPPT">
                                          Rating :-{" "}
                                        </span>
                                        <div className="Property_Cards_rating">
                                          <span>
                                            {generateStars(data.rating)}
                                          </span>
                                          <span className="font16BlackPPT ms-2">
                                            {data.user_ratings_total || 0}{" "}
                                            Review
                                          </span>
                                        </div>
                                      </div>
                                    </Card.Text>
                                  </Card.Body>
                                </Card>
                              </Col>
                            )}
                          </>
                        );
                      })}
                    </Row>
                  </div>
                </div>
              )}

              {nearByPlaces.length > 0 && (
                <div
                  className={
                    nearByPlaces.some(
                      (data) =>
                        data.type === "school" || data.type === "hospital"
                    )
                      ? "newPptSlide p-50"
                      : "d-none"
                  }
                >
                  <div className="d-flex justify-content-between align-items-start">
                    <div className="d-flex pptHeader w-100">
                      <div className="part"></div>
                      <div className="my-auto mx-2 w-90">SCHOOL</div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-start row mb-5">
                    <Row xs={1} md={2} lg={2} className="g-3">
                      {nearByPlaces.map((data, index) => {
                        return (
                          <>
                            {data.type === "school" && (
                              <Col>
                                <Card className="d-flex justify-content-between flex-row Property_Cards">
                                  <Card.Img
                                    variant="top"
                                    className="Card_Image"
                                    src={
                                      imageSrc[index]
                                        ? imageSrc[index]
                                        : SchoolPlaceholder
                                    }
                                  />
                                  <Card.Body className="ms-3">
                                    <Card.Title className="font18BoldBlackPPT">
                                      {data.name}
                                    </Card.Title>
                                    <Card.Text>
                                      <span className="font16BoldBlackPPT">
                                        Address :-{" "}
                                      </span>
                                      <span className="font16BlackPPT">
                                        {data.vicinity}
                                      </span>
                                    </Card.Text>
                                    <Card.Text>
                                      <span className="font16BoldBlackPPT">
                                        Distance :-{" "}
                                      </span>
                                      <span className="font16BlackPPT">
                                        {data.distance} km
                                      </span>
                                    </Card.Text>
                                    <Card.Text>
                                      <div className="d-flex">
                                        <span className="font16BoldBlackPPT">
                                          Rating :-{" "}
                                        </span>
                                        <div className="Property_Cards_rating">
                                          <span>
                                            {generateStars(data.rating)}
                                          </span>
                                          <span className="font16BlackPPT ms-2">
                                            {data.user_ratings_total || 0}{" "}
                                            Review
                                          </span>
                                        </div>
                                      </div>
                                    </Card.Text>
                                  </Card.Body>
                                </Card>
                              </Col>
                            )}
                          </>
                        );
                      })}
                    </Row>
                  </div>

                  <div className="d-flex justify-content-between align-items-start">
                    <div className="d-flex pptHeader w-100">
                      <div className="part"></div>
                      <div className="my-auto mx-2 w-90">HOSPITAL</div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-start row">
                    <Row xs={1} md={2} lg={2} className="g-3">
                      {nearByPlaces.map((data, index) => {
                        return (
                          <>
                            {data.type === "hospital" && (
                              <Col>
                                <Card className="d-flex justify-content-between flex-row Property_Cards">
                                  <Card.Img
                                    variant="top"
                                    className="Card_Image"
                                    src={
                                      imageSrc[index]
                                        ? imageSrc[index]
                                        : HospitalPlaceholder
                                    }
                                  />
                                  <Card.Body className="ms-3">
                                    <Card.Title className="font18BoldBlackPPT">
                                      {data.name}
                                    </Card.Title>
                                    <Card.Text>
                                      <span className="font16BoldBlackPPT">
                                        Address :-{" "}
                                      </span>
                                      <span className="font16BlackPPT">
                                        {data.vicinity}
                                      </span>
                                    </Card.Text>
                                    <Card.Text>
                                      <span className="font16BoldBlackPPT">
                                        Distance :-{" "}
                                      </span>
                                      <span className="font16BlackPPT">
                                        {data.distance} km
                                      </span>
                                    </Card.Text>
                                    <Card.Text>
                                      <div className="d-flex">
                                        <span className="font16BoldBlackPPT">
                                          Rating :-{" "}
                                        </span>
                                        <div className="Property_Cards_rating">
                                          <span>
                                            {generateStars(data.rating)}
                                          </span>
                                          <span className="font16BlackPPT ms-2">
                                            {data.user_ratings_total || 0}{" "}
                                            Review
                                          </span>
                                        </div>
                                      </div>
                                    </Card.Text>
                                  </Card.Body>
                                </Card>
                              </Col>
                            )}
                          </>
                        );
                      })}
                    </Row>
                  </div>
                </div>
              )}

              <div className="newPptSlide p-50">
                <div className="d-flex justify-content-between align-items-start mb-5">
                  <div className="d-flex pptHeader w-100">
                    <div className="part"></div>
                    <div className="my-auto mx-2 w-90">Contact Us</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-7 d-flex justify-content-center flex-column px-5">
                    <div className="font50Orange mb-4">{currentUser.name}</div>
                    <div
                      className="font24BlackPPT mb-4"
                      style={{ fontWeight: "400", wordBreak: "break-word" }}
                    >
                      {propertyDetails?.address}
                    </div>
                    <div className="font20BlackPPT">
                      {currentUser.mobileNumber && (
                        <div>
                          Mobile No.:{" "}
                          <span className="font-bold">
                            {currentUser.mobileNumber}
                          </span>
                        </div>
                      )}
                      <div>
                        Email Id:{" "}
                        <span className="font-bold">{currentUser.email}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-5">
                    <img className="roomImage" src={ContactUsImg} alt="" />
                  </div>
                </div>
              </div>

              {/* {propertyDetails && propertyDetails.address && (
                <div
                  className="newPptSlide p-50"
                  style={{ marginBottom: "0px" }}
                >
                  <div className="d-flex justify-content-between align-items-start mb-5">
                    <div className="d-flex pptHeader w-100">
                      <div className="part"></div>
                      <div className="my-auto mx-2 w-90">Map Location</div>
                    </div>
                  </div>
                  <LoadScript
                    googleMapsApiKey={`${process.env.REACT_APP_GOOGLE_API_KEY}`}
                  >
                    <GoogleMap
                      mapContainerStyle={mapContainerStyle}
                      center={coordinates}
                      zoom={15}
                    >
                      <Marker position={coordinates} />
                    </GoogleMap>
                  </LoadScript>
                </div>
              )} */}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Template1;
