import React, { useContext, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { CrossIcon } from "../shared/Icons";
import OTPInput from "otp-input-react";
import AxiosRequest from "../utils/AxiosRequest";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import User from "../context/UserContext";

const OtpModel = ({ showOtpModal, setShowOtpModal, userId, userEmail }) => {
  const [OTP, setOTP] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const { setCurrentUser } = useContext(User);

  const navigate = useNavigate();

  const [resendLoading, setResendLoading] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(false);

  const handleOTP = async (otp) => {
    try {
      setIsLoading(true);
      const reqObj = {
        id: userId,
        otp: otp ? otp : OTP,
      };

      console.log("userId = ", userId);
      const { data } = await AxiosRequest.post("user/verify-otp", reqObj);

      if (data?.statusCode === 200) {
        sessionStorage.setItem("authToken", data?.token);
        sessionStorage.setItem("currentUser", JSON.stringify(data?.data));
        setCurrentUser(data?.data);
        toast.success(data?.message);
        setIsLoading(false);
        setIsDisabled(false);
        // setModalShow(true);
        navigate("/my-generation");
      }
    } catch (err) {
      setIsLoading(false);
      setIsDisabled(false);
      toast.error("Invalid OTP");
    }
  };

  const handleOTPChange = (value) => {
    setOTP(value);
    setIsDisabled(value.length !== 4);
    if (value.length === 4) {
      handleOTP(value);
    }
  };

  const handleResendOTP = async () => {
    setResendLoading(true);
    setResendDisabled(true);
    try {
      const response = await AxiosRequest.post("/user/resend-otp", { userId });
      toast.success(response?.data?.message);
      setResendLoading(false);
    } catch (err) {
      setResendLoading(false);
      toast.error(err?.response?.data?.message || err?.message);
    } finally {
      setResendLoading(false);
      setResendDisabled(false);
    }
  };

  return (
    <Modal
      show={showOtpModal}
      onHide={() => setShowOtpModal(false)}
      backdrop="static"
      centered
      className="custom-otp-modal"
    >
      <Modal.Body>
        <div className="otp">
          <img className="otp-child" alt="" src="/group-8711.svg" />
          <div className="otp-item" />
          <div className="frame-parent-otp">
            <div className="heading3-parent-otp">
              <div className="heading3">
                <div className="heading-3">OTP Verification</div>
              </div>
              <div onClick={() => setShowOtpModal(false)}>
                <CrossIcon />
              </div>
            </div>
            <div className="description">
              <div className="a-4-digit-container">
                <p className="fs20">A 4 digit code has been sent</p>
                <p className="fs20t">to {userEmail}</p>
              </div>
            </div>
            <div className="input-field-parent">
              <OTPInput
                value={OTP}
                // // onChange={setOTP}
                onChange={(e) => {
                  handleOTPChange(e);
                }}
                OTPLength={4}
                otpType="number"
                className="d-flex justify-content-center align-items-center otpInput"
              />
            </div>
            <div className="button w-100" onClick={() => handleOTP()}>
              <button className="login" disabled={isLoading || isDisabled}>
                {isLoading ? (
                  <>
                    <Spinner animation="border" size="sm" className="me-2" />{" "}
                    Please wait...
                  </>
                ) : (
                  "Verify"
                )}
              </button>
            </div>
            <div className="already-have-an-account-login-wrapper">
              <div className="already-have-an-container">
                <span>
                  <span>Don’t receive OTP?</span>
                  <span className="span">{` `}</span>
                </span>
                <button
                  className="resend-otp"
                  disabled={resendLoading || resendDisabled}
                  onClick={handleResendOTP}
                >
                  {resendLoading ? (
                    <>
                      <Spinner animation="border" size="sm" className="me-2" />{" "}
                      Please wait...
                    </>
                  ) : (
                    "Resend OTP"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default OtpModel;
