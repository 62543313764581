import AxiosRequest from "../utils/AxiosRequest";


const getCurrentUser = async () => {
    try {
        const _currentUser = await AxiosRequest.get("/user/profile");
	    return _currentUser?.data?.data;
    } catch (err) {
        throw err;
    }
}

const updateProfile = async (payload) => {
    try {
        const response = await AxiosRequest.post("/user/profile", payload)
        return response
    } catch (err) {
        throw err;
    }
}

export {getCurrentUser, updateProfile}

