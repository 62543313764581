import { v4 as uuidv4 } from "uuid";
import * as mime from "mime-types";
import axios from "axios";
import AxiosRequest from "./AxiosRequest";

export const uploadImageWithSignUrl = async (file) => {
  // const ext = mime.extension(file.type);
  const { data } = await AxiosRequest.post("/user/get-signed-url", {
    file: `profile/${uuidv4()}.png`,
    contentType: "image/png",
  });
  const axiosInstance = axios.create();
  await axiosInstance.put(data.data.signedUrl, file, {
    headers: { "Content-Type": "image/png" },
  });

  return data.data.url;
};
