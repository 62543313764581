import {
  EmailIcon,
  FBIcon,
  LinkedIcon,
  PhoneIcon,
  YoutubeIcon,
} from "../../shared/Icons";
import { NavLink } from "react-router-dom";

export const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row justify-content-between">
          <div className="col col-md-6 col-lg-3">
            <div className="font24White mb-4">Navigation</div>
            <ul className="footerLink">
              <li>
                <NavLink className="link20White" to={"/"}>
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink className="link20White" to="/pricing">
                  Pricing
                </NavLink>
              </li>
              <li>
                <NavLink className="link20White" to="/faq">
                  FAQ
                </NavLink>
              </li>
              <li>
                <NavLink className="link20White" to="/contact">
                  Contact Us
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="col col-md-6 col-lg-3">
            <div className="font24White mb-4">Legal</div>
            <ul className="footerLink">
              <li>
                <NavLink className="link20White" to="/terms-and-conditions">
                  Terms & Conditions
                </NavLink>
              </li>
              <li>
                <NavLink className="link20White" to="/privacy-policy">
                  Privacy Policy
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="col col-md-6 col-lg-3">
            <div className="font24White mb-4">Contact Info</div>
            <ul className="footerLink">
              <li>
                <a className="link20White d-flex" href="mailto:james.s@restatecopilot.io" target="_blank" rel="noreferrer">
                  <span className="iconBG me-2">
                    <EmailIcon />
                  </span>
                  james.s@restatecopilot.io
                </a>
              </li>
              {/* <li>
                <NavLink className="link20White d-flex">
                  <span className="iconBG me-2">
                    <PhoneIcon />
                  </span>
                  +91 9825098250
                </NavLink>
              </li> */}
            </ul>
          </div>

          <div className="col col-md-6 col-lg-3">
            <div className="font24White mb-4">Social</div>
            <ul className="footerLink">
              <li>
                <a
                  className="link20White d-flex"
                  href="https://www.linkedin.com/company/restate-copilot/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="iconBG me-2">
                    <LinkedIcon />
                  </span>
                  LinkedIn
                </a>
              </li>
              <li>
                <a
                  className="link20White d-flex"
                  href="https://www.youtube.com/@RestatecCopilot"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="iconBG me-2">
                    <YoutubeIcon />
                  </span>
                  YouTube
                </a>
              </li>
              <li>
                <a
                  className="link20White d-flex"
                  href="https://www.facebook.com/profile.php?viewas=100000686899395&id=61557458574008"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="iconBG me-2">
                    <FBIcon />
                  </span>
                  Facebook
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};
