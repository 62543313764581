import axios from "axios";

export const processSlide = async (slide) => {
    const images = slide.querySelectorAll("img");
    const bgImageContainers = slide.querySelectorAll(".imgDiv1 ._img, .imgDiv2 ._img    , .imgDiv_imgContainer"); // Adjust the selectors based on your actual classes
    const bgImagePromises = Array.from(bgImageContainers).map(async (bgImageContainer) => {
        try {
            const style = window.getComputedStyle(bgImageContainer);
            const backgroundImage = style.getPropertyValue("background-image");
            const imageUrl = backgroundImage.replace(/url\(['"]?(.*?)['"]?\)/, "$1");
            
            if (imageUrl.startsWith("http")) {
                const response = await axios.get(imageUrl, { responseType: "blob" });
                if (response.status === 200) {
                    const blobURL = URL.createObjectURL(response.data);
                    bgImageContainer.style.backgroundImage = `url(${blobURL})`;
                } else {
                    console.error(`Failed to fetch background image: ${imageUrl}`);
                }
            }
        } catch (error) {
            console.error(`Error fetching background image: ${error.message}`);
        }
    });

    const imgPromises = Array.from(images).map(async (image) => {
        try {
            if (image && image.src.startsWith("http")) {
                const response = await axios.get(image.src, { responseType: "blob" });
                if (response.status === 200) {
                    const blobURL = URL.createObjectURL(response.data);
                    image.src = blobURL;
                } else {
                    console.error(`Failed to fetch image: ${image.src}`);
                }
            }
        } catch (error) {
            console.error(`Error fetching image: ${error.message}`);
        }
    });

    await Promise.all([...bgImagePromises, ...imgPromises]);
};

export const handleNValue = (e) => {
    if (!(/^[0-9]*$/.test(e.target.value))) {
        e.target.value = e.target.value.replace(/[^0-9]/g, '');
    }
    return e.target.value;
}
